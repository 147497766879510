<template>
    <v-app style="max-width:425px; margin-left:auto; margin-right:auto">
      <div :class="calculateBackgroundClass()" v-if="!ayaPay">
        <div style="padding: 16px;">
            <img src="https://s3iconimages.mymedicine.com.mm/mymedLogo.svg" alt="">
        </div>
        <div class="doctorBackground">
        </div>
        <div style="padding: 24px; padding-top: 56px;">
            <div style="line-height: 22px; font-weight: 500; font-size: 18px;">Welcome to My-Medicine</div>
            <div style="font-weight: 700; font-size: 34px; line-height: 44px;"><span style="">Trust Of </span><span style="color: #4aaaf2;">Millions</span></div>
            <div style="font-weight: 400; line-height: 22px;margin-top: 10px;">
                Over <span style="color: red;">1 million</span> customers trust our expert doctors from diverse specialties to care for their health.
            </div>
        <div style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 40px; gap: 16px;"></div>
        <v-btn elevation="0" block color="#4aaaf2" class="custom-button" style="text-transform: none; height: 48px;" @click="takeMeToLogin" ><span style="color: #fff; font-weight: 500;" >Login / Sign up</span></v-btn>

      </div>
    </div>
    <div :class="calculateBackgroundClass()" v-if="ayaPay" style="padding: 0 32px;">
        <div style="padding: 16px;">
            <img src="https://s3iconimages.mymedicine.com.mm/mymedLogo.svg" alt="">
        </div>
        <div style="font-weight: bold; font-size: 34px; line-height: 44px; margin-top: 64px;">
            <div>Your Privacy,</div>
            <div>Our Priority,</div>

        </div>
        <div>
            <img src="https://s3iconimages.mymedicine.com.mm/lock.gif" style="height: 180px; margin: 40px 0;" alt="">
        </div>
        <div style="padding: 24px; ">
            <div style="font-weight: 400; line-height: 22px;">
                <span style="color: #4aaaf2;">My-Medicine</span> values your privacy. To ensure the security of your medical records, we need to verify your identity.
            </div>
        <div style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 40px; gap: 16px;"></div>
        <v-btn  elevation="0" block color="#4aaaf2" class="custom-button" style="text-transform: none; height: 48px;" @click="takeMeToLogin"  :loading="otpLoading" :disabled="otpLoading"><span style="color: #fff; font-weight: 500;" >Verify You</span></v-btn>

      </div>
    </div>
</v-app>
</template>

<script>

import axios from "axios";
import languageChangePart from './languageChangePart.vue';
export default {
  components: { languageChangePart },
  data() {
    return {
        ayaPay: false,
        otpLoading: false,
        phone_number: '',
        platform: ''
    };
  },

  methods: {
    async takeMeToLogin() {
      if(this.platform == 'AYA') {
        this.otpLoading = true;
        try {
          await this.sendOTP();
        } catch (error) {
          alert('Something went wrong');
          console.log(error);
        }
      } else {
        this.$router.push({
            name: 'Verify'
        })
      }
    },
    async sendOTP() {
      try {
        this.otpLoading = true;
        await axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",{
          phoneNumber: this.phone_number,
        });
        this.otpLoading = false;
        this.$router.push({
            name: "VerifyOTP",
            params: {
              phoneNumber: this.phone_number, 
            }
          });
        } catch(error) {
        console.log(error);
      }

    },
    calculateBackgroundClass() {
        if(this.ayaPay) {
            return 'loginBackgroundAyaPay visbyRoundFont'
        } else {
            return 'loginBackground visbyRoundFont'
        }
    }
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    let alertMessage = '';
    console.log(typeof(params),params);
    const referrerObj = {};
    params.forEach((value, key) => {
        referrerObj[key] = value;
        alertMessage += `${key}: ${value}\n`;
    });
    // alert(alertMessage);
    console.log(alertMessage);
    console.log(params.referrer);
    console.log(params.referrer == 'ayapay');
    if(referrerObj.referrer == 'ayapay') {
        this.ayaPay = true;
        this.platform = 'AYA';
        this.phone_number = referrerObj.customerPhone;
        localStorage.setItem('platform', 'AYA');
        localStorage.setItem('ayaPhoneNumber', referrerObj.customerPhone);
    }
    this.currentCustomer = this.$cookies.get("customerToken");
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.Language = this.$i18n.locale;
    var access = this.$cookies.get("access");
    if(this.currentCustomer){

        var verifyCustomerTokenData = {
            "token" : this.currentCustomer,
            "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
        .then((verifyCustomerTokenDataResponse) => {
          let source= 'HOME';
          console.log(source)
          this.$router.push({
              name: 'ePharmacyOdooHome',
              params: { source: source }
          });
        })
        .catch((verifyCustomerTokenDataError)=>{
          this.$cookies.remove("customerToken");
          this.$cookies.remove("access");
        });
    }
    else{
      this.$cookies.remove("customerToken");
      this.$cookies.remove("access");
    }
  },

};
</script>

<style scoped>
.v-avatar {
  display: inline-flex;
  align-items: center;
}
.loginBackground {
  /* background: url('https://s3iconimages.mymedicine.com.mm/Rectangle 3.png'); */
  background: linear-gradient(to bottom right, #d9f2f5, #fcd2db);
  min-height: 100vh;
  background-size: cover;
}
.loginBackgroundAyaPay {
  background: linear-gradient(to bottom right, #d9f3f5 19%,#fff 49%, #fcd2db 113%);

  /* background: url('https://s3iconimages.mymedicine.com.mm/ayaPayBackground.png'); */
  min-height: 100vh;
  background-size: cover;
}
.doctorBackground {
  background: url('https://s3iconimages.mymedicine.com.mm/3dDoctors.svg');
  height: 300px;
  background-size: cover;
  /* border-radius: 0 0 40px 40px; */
}
.font_size_12_400 {
  font-size: 14px;
  font-weight: 400;
}
.mx-input {
  height: 40px !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
  .custom-button {
  text-transform: none;
  border-radius: 8px;
  font-size: 18px; letter-spacing: 0;
}
/* @media (min-width: 800px){
  .loginClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
  .MobileNumber{
    margin-top:14px !important
  }
  } */
</style>
